import React from "react";
import { Container, IconButton } from "@mui/material";
import {
  Call,
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  LocationOn,
  Twitter,
} from "@mui/icons-material";
const Footer = () => {
  return (
    <div className="relative">
      <>
        <div className="pt-20 pb-8 bg-white text-black ">
          <Container>
            <div className="flex sm:flex-row flex-col gap-10 justify-between">
              <div className="flex flex-col gap-2 max-w-xs">
                <h3 className="font-bold text-lg">ABOUT US</h3>
                <p>
                  Our group consists of creative individuals who possess skills
                  in innovation, critical thinking, and construction. Through
                  teamwork and the integration of our diverse perspectives and
                  expertise, we are capable of resolving challenges, generating
                  remedies, and simplifying processes for our clients.
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="font-bold text-lg">MORE INFORMATION</h3>
                <ul className="grid grid-cols-2 gap-3 list-disc ml-4">
                  <li>
                    <a className="text-left">Blog</a>
                  </li>
                  <li>
                    <a className="text-left">FAQs</a>
                  </li>
                  <li>
                    <a className="text-left">Blog</a>
                  </li>
                  <li>
                    <a className="text-left">FAQs</a>
                  </li>
                  <li>
                    <a className="text-left">Blog</a>
                  </li>
                  <li>
                    <a className="text-left">FAQs</a>
                  </li>
                  <li>
                    <a className="text-left">Blog</a>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-3 max-w-xs">
                <h3 className="font-bold text-lg">GET IN TOUCH</h3>
                <div className="flex items-start gap-3">
                  <LocationOn />
                  <p>
                  Near Azad Cement, 100 Foot Road, Ghitorni, New Delhi - 110030, India
                  </p>
                </div>
                <div className="flex items-start gap-3">
                  <Call />
                  <div className="flex flex-wrap gap-2">
                    <a href="tel:+91-8181812648" className="hover:underline">
                      +91 8181812648
                    </a>
                    <a href="tel:+91-8181812648" className="hover:underline">
                      +91 8181812648
                    </a>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <Email />
                  <a
                    href="mailto:director@rennov8.in"
                    className="hover:underline"
                  >
                    director@rennov8.in
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center pt-20">
              <div className="flex gap-4 justify-center">
                <IconButton>
                  <Twitter fontSize="large" />
                </IconButton>
                <IconButton>
                  <Instagram fontSize="large" />
                </IconButton>
                <IconButton>
                  <Facebook fontSize="large" />
                </IconButton>
                <IconButton href="https://www.linkedin.com/company/rennov8">
                  <LinkedIn fontSize="large" />
                </IconButton>
              </div>
              <div>© 2023 Rennov8 All rights reserved</div>
              {/* <div className="flex justify-center gap-2"> */}
              {/*   <span>Developed by </span> */}
              {/*   <a */}
              {/*     target="_blank" */}
              {/*     rel="noreferrer" */}
              {/*     href="https://devneural.com" */}
              {/*     className="hover:underline" */}
              {/*   > */}
              {/*     Devneural Solutions Pvt Ltd */}
              {/*   </a> */}
              {/* </div> */}
            </div>
          </Container>
        </div>
      </>
    </div>
  );
};

export default Footer;
