import React from 'react'
import Home from './pages/home'

// const App = () => {
//   return (
//     <Home/>
//   )
// }

const App = () => {
  return (
    <div>Not Available</div>
  )
}

export default App